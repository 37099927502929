<template>
  <div>
    <div
      class="
        part
        border-radius-6
        box-shadow-light-grey
        padding-20
        height-calc-type1
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div class="row">
          <el-button @click="getList">刷新数据</el-button>
          <el-select
            class="margin-right-twentyFour margin-left-twentyFour"
            v-model="params.platform"
            clearable
            placeholder="平台"
            style="width: 120px"
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="(item, index) in platformList"
              :label="item"
              :value="index"
              :key="index"
            >
            </el-option>
          </el-select>
          <el-select
            class="margin-right-twentyFour"
            style="width: 120px"
            v-model="params.categoryId"
            filterable
            clearable
            placeholder="选择标签"
            @change="handlePageChange(1)"
          >
            <el-option
              v-for="item in selectItems"
              :key="item.categoryId"
              :value="item.categoryId"
              :label="item.categoryName"
            >
              <span style="float: left">{{ item.categoryName }}</span>
            </el-option>
          </el-select>
          <el-input
            class="margin-right-twentyFour"
            placeholder="公众号"
            clearable
            v-model="params.channelName"
            style="width: 200px"
            @keydown.enter.native="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
          <el-input
            placeholder="用户名"
            clearable
            v-model="params.uname"
            style="width: 200px"
            class="margin-right-twentyFour"
            @keydown.enter.native="handlePageChange(1)"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handlePageChange(1)"
            ></el-button>
          </el-input>
          <el-button
            @click="showSetTag = true"
            type="primary"
            :disabled="!selectedList.length"
          >设置标签
          </el-button
          >
          <el-button
            @click="handleShowRate"
            type="warning"
            :disabled="!selectedList.length"
          >设置利率
          </el-button
          >
          <el-button
            @click="showCategory = true"
            :disabled="!selectedList.length"
          >公众号标签
          </el-button
          >
        </div>
        <el-button @click="addAdvert" type="primary" :loading="jumpLoading"
        >添加广告主
        </el-button
        >
      </div>
      <el-table
        height="69.3vh"
        v-loading="loading"
        :data="channelList"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="公众号ID" width="120">
        </el-table-column>
        <el-table-column prop="channelName" label="名称" min-width="180">
        </el-table-column>
        <el-table-column prop="platformStr" label="平台" min-width="180">
        </el-table-column>
        <el-table-column prop="uname" label="用户名" min-width="180">
        </el-table-column>
        <el-table-column prop="label" label="标签" min-width="100">
          <template slot-scope="scoped">
            <el-tag type="success" v-if="scoped.row.label == 11">抖音</el-tag>
            <el-tag type="success" v-if="scoped.row.label == 12">头条</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="rate" label="利率"></el-table-column>
        <el-table-column prop="status" label="状态" min-width="180">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.status === 0">已解绑</el-tag>
            <el-tag v-else>正常</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="isMarketing"
          label="是否接入腾讯广告"
          min-width="180"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.marketing.status === 1"
              @click="handleUnbind(scope.row.marketing.id)"
              style="cursor: pointer"
            >已绑定
            </el-tag
            >
            <el-tag type="warning" v-else-if="scope.row.marketing.status === 0"
            >未接入
            </el-tag
            >
            <el-tag type="danger" v-else-if="scope.row.marketing.status === 2"
            >已解绑
            </el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="action"
          label="操作"
          width="300px"
          min-width="180"
        >
          <template slot-scope="scope">
            <el-button
              class="button-small"
              type="warning"
              @click="handleChangeName(scope.row)"
            >修改名称
            </el-button
            >
            <el-button
              class="button-small"
              type="primary"
              @click="
                isBindUser = true;
                current = scope.row;
              "
            >设置管理者
            </el-button>
            <el-button
              class="button-small"
              @click="
              showCategory = true;
              current = scope.row;
              isMultiple = false;
              "
            >公众号标签
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 设置管理者 -->
    <el-drawer
      direction="rtl"
      :title="`${current.channelName} 设置管理者`"
      size="1200px"
      :visible.sync="isBindUser"
    >
      <bind-user
        :show="isBindUser"
        :channel="current"
        @close="isBindUser = false"
      />
    </el-drawer>
    <!-- 设置标签 -->
    <el-dialog
      title="标签设置"
      :visible.sync="showSetTag"
      center
      width="450px"
      top="20vh"
      append-to-body
    >
      <el-form label-width="120px" class="form-container">
        <el-form-item label="标签：">
          <el-select v-model="selectedTag" placeholder="选择标签">
            <el-option label="抖音" :value="11"></el-option>
            <el-option label="头条" value="12"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancelTag">取消</el-button>
        <el-button :loading="subTagLoading" @click="handleSubTag"
        >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置利率 -->
    <setRate
      :show="showSetRate"
      :selectedList="selectedList"
      @success="handleSetRateSuccess"
      @cancel="showSetRate = false"
    />

    <select-category
      :show="showCategory"
      :id="current.id"
      :selectItems="selectItems"
      :isMultiple="isMultiple"
      @close="showCategory=false"
      :selectedList="selectedList"
      @refresh="getList"
    />
  </div>
</template>

<script>
import {
  getAllChannel,
  getAdvertCodeLink,
  unbindAd,
  changeTags,
  changeChannelName,
} from "@/api/account";
import {platformList} from "@/assets/js/options";
import BindUser from "./bindUser.vue";
import setRate from "./setRate.vue";
import selectCategory from "./selectCategory.vue";
import {getClassifyList} from "@/api/classify";

export default {
  name: "channel",
  props: {
    params: Object,
  },
  data() {
    return {
      loading: false,
      page: 1,
      total: 0,
      channelList: [],
      isBindUser: false,
      current: {},
      jumpLoading: false,
      platformList,
      showSetTag: false,
      showSetRate: false,
      selectedTag: [],
      selectedList: [],
      subTagLoading: false,
      showCategory: false,
      selectItems: [],
      isMultiple: false,
    };
  },
  mounted() {
    this.getList();
    this.initData();
  },
  methods: {
    initData() {
      getClassifyList({type: 1, status: 1}).then((res) => {
        this.selectItems = res.map((item) => {
          return {
            categoryId: item.id,
            categoryName: item.name
          }
        });
      });
    },
    handleChangeName(data) {
      this.$prompt("请输入名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.channelName,
      })
        .then(({value}) => {
          if (!value) {
            return this.$message.error("请填写名称");
          }
          changeChannelName(data.id, {
            channelName: value,
          })
            .then((res) => {
              this.$message.success("修改成功");
              this.handlePageChange(1);
            })
            .finally(() => {
            });
        })
        .catch(() => {
        });
    },
    handleShowRate() {
      let flag = this.selectedList.some((item) => {
        return item.platform !== 13;
      });
      if (flag) return this.$message.error("仅番茄快应用可设置利率");
      this.showSetRate = true;
    },
    handleSetRateSuccess() {
      this.showSetRate = false;
      this.handlePageChange(1);
    },
    handleCancelTag() {
      this.selectedTag = null;
      this.showSetTag = false;
    },
    handleSubTag() {
      if (!this.selectedTag) {
        return this.$message.error("请选择标签");
      }
      let ids = this.selectedList.map((item) => {
        return item.id;
      });
      this.subTagLoading = true;
      changeTags(ids.join(","), this.selectedTag)
        .then((res) => {
          this.$message.success("修改成功");
          this.handlePageChange();
        })
        .finally(() => {
          this.handleCancelTag();
          this.subTagLoading = true;
        });
    },
    handleSelectionChange(data) {
      this.selectedList = data;
      this.isMultiple = true;
    },
    handleUnbind(id) {
      this.$confirm("确定要解绑?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unbindAd(id).then((res) => {
            this.$message.success("解绑成功");
            this.getList();
          });
        })
        .catch(() => {
        });
    },
    addAdvert() {
      this.jumpLoading = true;
      getAdvertCodeLink()
        .then((res) => {
          window.open(res, "_blank");
        })
        .finally(() => {
          this.jumpLoading = false;
        });
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getAllChannel({
        page: this.page,
        channelName: this.params.channelName,
        platform: this.params.platform,
        uname: this.params.uname,
        categoryId: this.params.categoryId,
      })
        .then((res) => {
          this.channelList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    BindUser,
    setRate,
    selectCategory,
  },
};
</script>

<style lang='scss' scoped>
.part {
  background: #fff;
}

.operation {
  background: #fff;
  // padding: 0 15px
}

/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
